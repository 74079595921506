<template>
    <div v-if="!item.hide" class="open-response mb-0" :class="{ show_answer_key: showAnswerKey }">
        <!-- Number and Question. -->
        <div
            class="d-inline-flex mb-0 w-100"
            :class="{
                'align-items-end': normalLinesWithAfter && !isQuestionMultiline && questionWidth() / fullPageWidth <= 0.85,
            }"
            :style="{
                minHeight: numberAndQuestionHeight,
            }"
        >
            <span
                v-if="item.numbering && item.numbering.number > 0"
                class="preview-number d-flex align-items-top"
                :class="{ 'number-hide': item.numbering.hidden }"
                v-html="
                    $store.getters['document/format_number'](
                        (item.numbering && item.numbering.number) || 1,
                        item.numbering && item.numbering.format,
                    )
                "
            ></span>
            <span
                ref="question"
                class="mb-0 flex-shrink-0"
                style="max-width: 7.2in; overflow-wrap: break-word"
                v-html="item.data.subtitle"
            ></span>
            <div
                v-if="item.data.answer_type === 'single' && questionAndLine() <= fullPageWidth"
                class="d-flex flex-column justify-content-end ml-1 position-relative"
            >
                <AnswerSpan
                    v-if="showAnswerKey"
                    :answer="item.data.answer"
                    class="d-block text-center text-nowrap position-absolute inset-0"
                    :style="{ maxWidth: fullPageWidth - questionWidth() + 'in', marginBottom: `-0.1em !important` }"
                />
                <span
                    class="response-lines align-self-start"
                    :style="{
                        width: `${item.data.line_length}in`,
                    }"
                ></span>
            </div>
            <span
                v-if="normalLinesWithAfter && !isQuestionMultiline && questionWidth() / fullPageWidth <= 0.85"
                class="response-lines ml-1 position-relative"
                :style="{
                    width: `${fullPageWidth - questionWidth()}in`,
                    lineHeight: `${paragraphedAnsMarginTop + 0.1}in`,
                }"
            >
                <AnswerSpan
                    v-if="showAnswerKey"
                    :answer="linesAfterAnswers[0]"
                    class="d-flex align-items-end text-nowrap mb-3"
                    :style="{
                        maxWidth: `${fullPageWidth - questionWidth()}in`,
                        fontSize: `${computedLineHeight / 3}in`,
                    }"
                />
                <div :id="`paragraph-measure-${item.id}`" class="position-absolute inset-0 ml-4"></div>
            </span>
        </div>

        <!-- Answer Type: Single, But line and question won't fit in single line -->
        <div v-if="item.data.answer_type === 'single' && questionAndLine() > fullPageWidth" class="mx-3 ml-4">
            <div
                class="response-lines overflow-hidden"
                :style="{
                    width: `${item.data.line_length}in`,
                    marginTop: `15px`,
                    minHeight: `calc(${style.font_space}em + 3px)`,
                }"
            >
                <AnswerSpan
                    v-if="showAnswerKey"
                    :answer="item.data.answer"
                    class="d-block text-left text-nowrap"
                    :style="{ minHeight: '1em' }"
                />
            </div>
        </div>

        <!-- Answer Type: Lines, Line Type: Normal -->
        <div
            v-if="item.data.answer_type === 'lines' && item.data.line_style === 'normal'"
            class="ml-4 position-relative overflow-hidden"
        >
            <div
                v-for="(x, index) in parseInt(numberOfLines)"
                :key="index"
                :ref="`line-${item.id}-${index}`"
                class="response-lines"
                :style="{ marginTop: item.data.line_height + 'in !important' }"
            ></div>

            <ParagraphedAnswer
                v-if="showAnswerKey"
                class="position-absolute inset-0"
                :style="{
                    lineHeight: `${computedLineHeight}in !important`,
                    marginTop: `${paragraphedAnsMarginTop}in`,
                    fontSize: `${computedLineHeight / 3}in`,
                }"
                :answer="item.data.line_position === 'after' ? linesAfterAnswers[1] : item.data.answer"
            />
        </div>

        <!-- Answer Type: Lines, Line Type: Handwriting -->
        <div
            v-if="item.data.answer_type === 'lines' && item.data.line_style === 'handwriting'"
            :id="`handwriting-response-${item.id}`"
            class="position-relative overflow-hidden"
        >
            <div
                v-for="(x, index) in parseInt(item.data.number_of_lines)"
                :key="index"
                :ref="`base-parent-line-${item.id}-${index}`"
                class="handwriting-practice-open-response mt-4 ml-4"
                :style="{
                    overflowX: 'clip',
                }"
            >
                <div
                    :ref="`line-${item.id}-${index}`"
                    class="handwriting position-relative d-flex align-content-end"
                    :style="{
                        fontSize: item.data.line_height + 'in',
                        marginBottom:
                            index + 1 === item.data.number_of_lines
                                ? parseFloat(item.data.line_height) * 0.25 + 'in'
                                : 'unset',
                    }"
                >
                    <div
                        v-if="showAnswerKey"
                        class="position-absolute inset-0 text-danger d-flex flex-column justify-content-end"
                        :style="{
                            fontSize: handwritingLineHeight * 0.75 + 'in',
                            paddingTop:
                                handwritingLineHeight * 0.25 -
                                convertPixelToInches(handWritingBordersPx + lineTopMargin) +
                                'in',
                            lineHeight: 0.8,
                        }"
                    >
                        <span>
                            {{ answerLines[index] ?? '' }}
                        </span>
                    </div>
                </div>
            </div>

            <div
                :id="`paragraph-measure-${item.id}`"
                class="position-absolute inset-0 ml-4"
                :style="{ fontSize: handwritingLineHeight * 0.75 + 'in', lineHeight: 0.8 }"
            ></div>
        </div>

        <!-- Answer Type: Blank -->
        <div v-if="item.data.answer_type === 'blank'" class="mb-0 d-flex">
            <!-- Add number spacing for answer key -->
            <span
                v-if="item.numbering && item.numbering.number > 0"
                class="preview-number d-flex align-items-top"
                :class="{ 'number-hide': true }"
                v-html="
                    $store.getters['document/format_number'](
                        (item.numbering && item.numbering.number) || 1,
                        item.numbering && item.numbering.format,
                    )
                "
            ></span>
            <ParagraphedAnswer
                v-if="showAnswerKey"
                :answer="item.data.answer"
                :style="{
                    lineHeight: blankFontSize + 0.1 + 'in',
                    fontSize: blankFontSize + 'in',
                    height: item.data.answer_height + 'in',
                    overflow: 'hidden',
                    display: `inline-table !important`,
                }"
            />
            <div v-else :style="{ height: item.data.answer_height + 'in' }"></div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import indexToLetter from '../../mixins/IndexToLetter'
import { mapGetters } from 'vuex'
import AnswerSpan from '../AnswerSpan.vue'
import ParagraphedAnswer from '../ParagraphedAnswer.vue'
import { fixUnmatchedClosingTags, fixUnmatchedOpeningTags } from '../../common/helpers'

export default defineComponent({
    name: 'OpenResponsePreview',
    components: { AnswerSpan, ParagraphedAnswer },
    mixins: [indexToLetter],
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            fullPageWidth: 7.2,
            lineBorderHeight: 0,
            lineTopMargin: 0,
            handWritingBordersPx: 0,
            borderThickness: 0,
            lineFontSize: 14,
        }
    },
    computed: {
        isQuestionMultiline() {
            return this.questionLines() > 1
        },
        numberOfLines() {
            if (
                !this.isQuestionMultiline &&
                this.normalLinesWithAfter &&
                this.questionWidth() / this.fullPageWidth <= 0.85
            ) {
                return this.item.data.number_of_lines - 1
            }

            return this.item.data.number_of_lines
        },
        ...mapGetters({
            style: 'document/documentStyle',
            globalShowAnswerKey: 'document/showAnswerKey',
        }),
        showAnswerKey() {
            return this.globalShowAnswerKey && this.item.data.has_answer
        },
        computedLineHeight() {
            const height = this.convertPixelToInches(parseFloat(this.lineBorderHeight))
            return parseFloat(this.item.data.line_height) + height
        },
        handwritingLineHeight() {
            const borders = this.convertPixelToInches(this.handWritingBordersPx)
            return parseFloat(this.item.data.line_height) - borders
        },
        answerLines() {
            const container = document.getElementById(`paragraph-measure-${this.item.id}`)
            let answer =
                this.item.data.line_style == 'handwriting' ? this.item.data.handwritingAnswer : this.item.data.answer
            if (
                !container ||
                this.item.data.answer_type != 'lines' ||
                this.item.data.line_style != 'handwriting' ||
                !answer
            ) {
                return []
            }

            const words = answer.split(' ')
            const lines = []
            let line = ''

            const testElement = this.createTestElement(container)
            document.body.appendChild(testElement)

            words.forEach((word) => {
                const testLine = line + word + ' '
                testElement.innerHTML = testLine

                if (testElement.offsetWidth > container.offsetWidth) {
                    // If the test line width exceeds the container width, start a new line
                    if (line.trim()) {
                        lines.push(line.trim())
                    }
                    line = word + ' '
                } else if (testElement.offsetWidth === container.offsetWidth) {
                    // If the test line width exactly matches the container width, add the line and start a new one
                    lines.push(testLine.trim())
                    line = ''
                } else {
                    line = testLine
                }
            })

            // Add the last line
            lines.push(line.trim())
            document.body.removeChild(testElement)
            return lines
        },
        paragraphedAnsMarginTop() {
            return this.item.data.line_height - this.computedLineHeight / 1.4
        },
        blankFontSize() {
            return Math.min(this.item.data.answer_height, this.convertPixelToInches(this.style.font_size))
        },
        linesAfterAnswers() {
            const container = document.getElementById(`paragraph-measure-${this.item.id}`)
            let answer = this.item.data.answer
            if (!container || this.item.data.answer_type != 'lines' || !answer) {
                return ['', answer ?? '']
            }
            container.style.fontSize = `${this.computedLineHeight / 3}in`

            const words = answer.split(' ')
            const lines = []
            let line = ''

            // Create a test element to measure text
            const testElement = this.createTestElement(container)
            testElement.style.fontSize = `${this.computedLineHeight / 3}in`
            testElement.style.width = 'auto'
            document.body.appendChild(testElement)

            for (let index = 0; index < words.length; index++) {
                const testLine = line + words[index] + ' '
                testElement.innerHTML = testLine

                if (testElement.offsetWidth > container.offsetWidth) {
                    // If the test line width exceeds the container width, it's a new line
                    lines.push(fixUnmatchedOpeningTags(line.trim()))
                    lines.push(fixUnmatchedClosingTags(words.slice(index).join(' ')))
                    break
                } else {
                    line = testLine
                }
            }

            if (lines.length == 0) lines.push(line.trim())

            document.body.removeChild(testElement)
            return lines
        },
        normalLinesWithAfter() {
            return (
                this.item.data.answer_type === 'lines' &&
                this.item.data.line_style === 'normal' &&
                this.item.data.line_position === 'after'
            )
        },
        numberAndQuestionHeight() {
            return this.normalLinesWithAfter &&
                !this.isQuestionMultiline &&
                this.questionWidth() / this.fullPageWidth <= 0.85
                ? `${this.computedLineHeight / 2}in`
                : 'auto'
        },
    },
    mounted() {
        this.setMultiLinesDivProperties()
    },
    watch: {
        answerLines: {
            handler() {
                this.setMultiLinesDivProperties()
            },
            deep: true,
            immediate: true,
        },
    },
    methods: {
        questionLines() {
            let elHeight = this.$refs.question ? this.$refs.question.offsetHeight : 0

            let lineHeight = parseInt(this.style.font_space * this.style.font_size)

            return Math.floor(elHeight / lineHeight)
        },
        convertPixelToInches(pixel) {
            return pixel / 96 // 1in = 96px
        },
        questionWidth() {
            let inputText = this.item.data.subtitle ? this.item.data.subtitle.replace(/<\/?[^>]+(>|$)/g, '') : ''

            let context = document.createElement('canvas').getContext('2d')

            context.font = `${this.style.font_size}px ${this.style.font}`

            let width = Math.ceil(context.measureText(inputText).width)

            return this.convertPixelToInches(width).toFixed(1)
        },
        questionAndLine() {
            return parseFloat(this.questionWidth()) + parseFloat(this.item.data.line_length)
        },
        setMultiLinesDivProperties() {
            const firstDiv = this.$refs[`line-${this.item.id}-0`]
            const baseDiv = this.$refs[`base-parent-line-${this.item.id}-0`]

            if (firstDiv && firstDiv[0]) {
                const height = firstDiv[0].getBoundingClientRect().height
                const style = window.getComputedStyle(firstDiv[0])
                const marginTop = parseFloat(style.marginTop)
                this.lineBorderHeight = parseFloat(height)
                this.lineFontSize = style.getPropertyValue('font-size')

                //Factor in border thicknesses
                const borderTopWidth = parseInt(style.borderTopWidth)
                const borderBottomWidth = parseInt(style.borderBottomWidth)
                if (this.item.data.answer_type === 'lines' && this.item.data.line_style === 'handwriting') {
                    if (baseDiv && baseDiv[0]) {
                        const parentStyle = window.getComputedStyle(baseDiv[0])
                        this.lineTopMargin = parseFloat(parentStyle.marginTop)
                    } else {
                        this.lineTopMargin = marginTop
                    }

                    const beforeElement = window.getComputedStyle(firstDiv[0], '::before')
                    const beforeBorderWidth =
                        parseInt(beforeElement.getPropertyValue('border-bottom-width')) +
                        parseInt(beforeElement.getPropertyValue('border-top-width'))
                    this.handWritingBordersPx = beforeBorderWidth + borderTopWidth + borderBottomWidth
                } else {
                    this.borderThickness = borderTopWidth + borderBottomWidth
                }
            }
        },
        createTestElement(container) {
            // Delete the test element if it exists
            if (document.getElementById('test-element')) {
                document.body.removeChild(document.getElementById('test-element'))
            }

            // Create a test element to measure text
            const testElement = document.createElement('div')
            testElement.id = 'test-element'
            testElement.style.position = 'absolute'
            testElement.style.bottom = '-9999px'
            testElement.style.whiteSpace = 'nowrap'
            testElement.style.width = container.getBoundingClientRect().width
            testElement.style.font = window.getComputedStyle(container).font

            return testElement
        },
    },
})
</script>

<style>
.inset-0 {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
</style>
