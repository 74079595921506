<template>
    <AnswerSpan :answer="answer" />
</template>

<script>
import AnswerSpan from './AnswerSpan.vue'
export default {
    components: { AnswerSpan },
    props: {
        answer: {
            type: String,
            default: '',
        },
    },
}
</script>
