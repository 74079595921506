<template>
    <div v-if="!item.hide" class="d-flex mb-0">
        <div
            v-if="item.numberable && item.numbering && item.numbering.number > 0"
            class="mr-2"
            v-html="
                $store.getters['document/format_number'](
                    (item.numbering && item.numbering.number) || 1,
                    item.numbering && item.numbering.format,
                )
            "
        ></div>
        <div class="flex-grow-1" style="overflow-x: clip">
            <div
                v-for="(x, index) in parseInt(item.data.number_of_lines)"
                :Key="index"
                class="handwriting-practice w-auto pb-2"
                :class="{ 'last-item': item.data.number_of_lines > 1 && index === item.data.number_of_lines }"
                :style="{
                    transformOrigin: 'left top',
                    transform: `scale(
                        ${hwFontScale}
                    )`,
                    marginBottom: `${item.data.line_height - (item.data.line_height > 40 ? 14 : 18)}px`,
                }"
            >
                <div
                    class="d-flex handwriting"
                    :style="{
                        borderColor: item.data.guide_line_color,
                    }"
                >
                    <div v-for="(partial, index) in partials" :key="'hw-partial-' + index" class="hw-practice">
                        <div :style="inlineStyle(partial.style)" v-html="partial.text"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import {
    HANDWRITING_TYPE_SOLID,
    HANDWRITING_TYPE_TRACE,
    HANDWRITING_TYPE_STROCK,
    STANDARD_PRINT,
    STANDARD_CURSIVE,
    FONT_TYPE_REGULAR,
    FONT_TYPE_DASHED,
    FONT_TYPE_STROKED,
    handwritingFontSizes,
    hwFontTransitions,
    DEFAULT_HW_FONT_SIZE,
} from '../../store/helpers/documentHelpers'

export default defineComponent({
    name: 'HandwritingPreview',
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            HANDWRITING_TYPE_SOLID,
            HANDWRITING_TYPE_TRACE,
            HANDWRITING_TYPE_STROCK,
        }
    },
    computed: {
        partials() {
            return this.item.data.partials
                ? this.item.data.partials.map((p) => ({
                      text: this.item.data.subtitle?.substring(p.start, p.end) || '',
                      style: p.style,
                  }))
                : []
        },
        cursiveFont() {
            return this.item.data[`hw_font_${this.item.data.cursive_style}`]
        },
        hwFontScale() {
            return this.item?.data?.line_height ? this.item.data.line_height / DEFAULT_HW_FONT_SIZE : 1
        },
    },
    watch: {
        'item.data.cursive_style': {
            deep: true,
            handler: function () {},
        },
    },
    methods: {
        inlineStyle(style) {
            let cursiveFont = this.cursiveFont
            if (cursiveFont === STANDARD_CURSIVE || cursiveFont === STANDARD_PRINT) {
                let styleName
                if (style === HANDWRITING_TYPE_SOLID) {
                    styleName = FONT_TYPE_REGULAR
                } else if (style === HANDWRITING_TYPE_TRACE) {
                    styleName = FONT_TYPE_DASHED
                } else {
                    styleName = FONT_TYPE_STROKED
                }

                let styles = `font-family: '${cursiveFont === STANDARD_PRINT ? 'Precursive' : 'Cursive'} ${styleName}';`
                if (style === HANDWRITING_TYPE_TRACE) {
                    styles += `font-weight: bold;`
                }
                return styles
            } else {
                let styles = {
                    fontFamily: cursiveFont,
                }
                if (style === HANDWRITING_TYPE_TRACE) {
                    styles.opacity = 0.25
                }

                if (handwritingFontSizes[cursiveFont]) {
                    styles.fontSize = handwritingFontSizes[cursiveFont]
                }

                if (hwFontTransitions[cursiveFont]) {
                    styles.transform = `translateY(${hwFontTransitions[cursiveFont]})`
                }

                return styles
            }
        },
    },
})
</script>

<style lang="scss" scoped>
.handwriting p {
    color: black;
}
.hw-practice {
    white-space: pre;
}
</style>
