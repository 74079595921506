<template>
    <div v-if="!item.hide" class="matching-preview mb-0">
        <div v-if="item.numbering && item.numbering.number > 0" class="d-inline-flex mb-0">
            <span
                class="preview-number d-flex align-items-top"
                v-html="
                    $store.getters['document/format_number'](
                        (item.numbering && item.numbering.number) || 1,
                        item.numbering && item.numbering.format,
                    )
                "
            ></span>
            <span class="mb-0" v-html="item.data.subtitle"></span>
        </div>
        <div
            v-cloak
            ref="matching-container"
            v-observe-visibility="visibilityChanged"
            class="matching-container columns pt-4 pl-3 pr-3"
            :style="{
                borderWidth: item.style.border_width + 'px',
                borderStyle: item.style.border_style,
                borderColor: item.style.border_color,
                fontSize: item.style.font_size + 'px',
            }"
        >
            <div ref="termColumn" class="column">
                <div
                    v-for="(mItem, index) in item.data.pairs"
                    :key="'item-' + index"
                    class="item d-flex align-items-start"
                    :style="{ textAlign: item.data.settings.align_clues }"
                >
                    <!-- Question number -->
                    <span
                        v-if="item.data.settings.display_index"
                        class="number"
                        v-html="$store.getters['document/format_number'](index + 1, 2)"
                    ></span>

                    <span class="d-flex" :class="[alignNumberingClass, alignDotsClass]">
                        <!-- blank before -->
                        <span
                            v-if="showBlankBeforeTerm"
                            class="answer margin-right"
                            :class="document.show_answer_key ? 'text-answer' : ''"
                            :style="{ borderColor: item.style.color }"
                        >
                            <span class="text-pre letterAnswer" :class="{ hideAnswers: !document.show_answer_key }">
                                {{ getAnswer(index) }}
                            </span>
                        </span>

                        <!-- term, possibly scrambled if desired -->
                        <div class="d-flex w-100" :class="alignItems">
                            <div
                                class="d-flex justify-content-center align-items-center"
                                :class="textPlacementClass"
                                @click="handleTerm(index)"
                            >
                                <InlineImage
                                    v-if="mItem?.term_image?.length"
                                    :image-id="mItem.term_image"
                                    :size="item.data.settings?.images?.size"
                                />
                                <div>
                                    <span
                                        class="term text-pre"
                                        style="word-break: break-word"
                                        v-text="
                                            item.data.settings.letter_scramble
                                                ? scrambleWord(mItem.term, index + 1)
                                                : mItem.term
                                        "
                                    ></span>
                                </div>
                            </div>
                        </div>

                        <!-- blank after -->
                        <span
                            v-if="showBlankAfterTerm"
                            class="answer margin-left"
                            :class="document.show_answer_key ? 'text-answer' : ''"
                            :style="{ borderColor: item.style.color }"
                        >
                            <span class="text-pre letterAnswer" :class="{ hideAnswers: !document.show_answer_key }">
                                {{ getAnswer(index) }}
                            </span>
                        </span>

                        <!-- dot for drawing lines -->
                        <span
                            v-show="showDots()"
                            :ref="`question-${index}`"
                            class="dot"
                            :style="{
                                backgroundColor: item.style.color,
                            }"
                        ></span>
                    </span>
                </div>
            </div>

            <div ref="definitionColumn" class="column">
                <div
                    v-for="(sItem, index) in shuffled"
                    :key="'matching-items-' + index"
                    class="item d-flex align-items-center"
                >
                    <span
                        v-show="showDots()"
                        :ref="'answer-' + index"
                        class="dot"
                        :style="{ backgroundColor: item.style.color }"
                    ></span>

                    <span class="d-flex align-items-start">
                        <!-- Answer Letter NOTE:: if they are using blanks for matching, letters MUST be displayed -->
                        <span
                            v-if="item.data.settings.display_index"
                            class="number"
                            v-text="indexToLetter(index) + '.'"
                        ></span>

                        <div
                            class="d-flex justify-content-center align-items-center"
                            :class="textPlacementClass"
                            @click="getDefinition(sItem)"
                        >
                            <InlineImage
                                v-if="sItem?.definition_image?.length"
                                :image-id="sItem.definition_image"
                                :size="item.data.settings?.images?.size"
                            />
                            <span class="definition text-pre" style="word-break: break-word">
                                {{ sItem.definition }}
                            </span>
                        </div>
                    </span>
                </div>
            </div>

            <div v-if="item.data.settings.match_type === 'line' && document.show_answer_key" ref="lines" class="lines"></div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import Vue from 'vue'
import Chance from 'chance'
import range from 'lodash.range'
import { ResizeObserver } from 'resize-observer'
import { mapGetters, mapState } from 'vuex'
import indexToLetter from '../../mixins/IndexToLetter'
import VueObserveVisibility from 'vue-observe-visibility'
import InlineImage from '../widgets/InlineImage.vue'
import InlineImagesMixin from '../../mixins/InlineImages'

Vue.use(VueObserveVisibility)

export default defineComponent({
    name: 'MatchingPreview',
    components: {
        InlineImage,
    },
    mixins: [indexToLetter, InlineImagesMixin],
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapState(['document']),
        ...mapGetters({
            numbersAreVisible: 'document/numbersAreVisible',
            canBeShuffled: 'document/canBeShuffled',
            worksheet: 'document/worksheet',
            style: 'document/documentStyle',
            currentWidget: 'document/currentWidget',
            inlineImages: 'document/worksheetInlineImages',
        }),
        shuffled() {
            let items = this.getAnswerArray()
            let c = new Chance(this.item.data.shuffle_seed)
            return c.shuffle(items)
        },
        loadCompleted() {
            return {
                fonts: this.$fonts.ready,
            }
        },
        alignItems() {
            if (this.item.data.settings?.images?.alignment)
                return `justify-content-${this.item.data.settings.images.alignment}`

            return 'justify-content-center'
        },
        alignNumberingClass() {
            return this.item.data.settings.match_type === 'letter' ? 'align-items-start' : 'align-items-center'
        },
        alignDotsClass() {
            return this.item.data.settings.line_up_dots && this.item.data.settings.match_type === 'line'
                ? 'w-100 justify-content-between'
                : ''
        },
        showBlankBeforeTerm() {
            return this.item.data.settings.match_type == 'letter' && this.item.data.settings.blank_position == 'before'
        },
        showBlankAfterTerm() {
            return this.item.data.settings.match_type === 'letter' && this.item.data.settings.blank_position === 'after'
        },
        textPlacementClass() {
            return this.item.data.settings?.images?.text_placement === 'below' ? 'flex-column' : 'flex-column-reverse'
        },
    },
    watch: {
        loadCompleted: {
            deep: true,
            handler() {
                this.$nextTick(() => {
                    this.drawLines()
                })
            },
        },
        item: {
            deep: true,
            handler() {
                this.$nextTick(() => {
                    this.drawLines()
                })
            },
        },
        style: {
            deep: true,
            handler() {
                this.$nextTick(() => {
                    this.drawLines()
                })
            },
        },
    },
    mounted() {
        this.$nextTick(() => {
            const ro = new ResizeObserver(() => this.drawLines())
            if (this.$refs.definitionColumn) ro.observe(this.$refs.definitionColumn)
            if (this.$refs.termColumn) ro.observe(this.$refs.termColumn)
        })
    },
    updated() {
        this.$nextTick(() => this.drawLines())
    },
    methods: {
        visibilityChanged(isVisible) {
            this.isVisible = isVisible
            this.drawLines()
        },
        getAnswerArray() {
            let answerArray = []

            if (this.item.data.pairs) {
                for (var i = 0; i < this.item.data.pairs.length; i++) {
                    answerArray.push({
                        term: this.item.data.pairs[i].term,
                        definition: this.item.data.pairs[i].definition,
                        fake: false,
                        index: i,
                        term_image: this.item.data.pairs[i].term_image,
                        definition_image: this.item.data.pairs[i].definition_image,
                    })
                }
            }
            if (this.item.data.fakeAnswers.length) {
                for (let i = 0; i < this.item.data.fakeAnswers.length; i++) {
                    answerArray.push({
                        definition: this.item.data.fakeAnswers[i].text,
                        definition_image: this.item.data.fakeAnswers[i].image,
                        fake: true,
                        index: i,
                    })
                }
            }

            return answerArray
        },
        showDots() {
            if (this.item.data.settings.match_type == 'line') {
                return true
            } else {
                return false
            }
        },
        drawLines() {
            if (this.document.show_answer_key && this.showDots() && this.$refs.lines) {
                let wrapper = this.$refs['matching-container']

                //remove any existing children from the node
                while (this.$refs.lines.hasChildNodes()) {
                    this.$refs.lines.removeChild(this.$refs.lines.firstChild)
                }

                //create a canvas
                var canvas = document.createElement('canvas')
                canvas.width = wrapper.offsetWidth * 2
                canvas.height = wrapper.offsetHeight * 2
                canvas.style.width = wrapper.offsetWidth + 'px'
                canvas.style.height = wrapper.offsetHeight + 'px'
                canvas.style.position = 'absolute'
                canvas.style.top = 0
                canvas.style.left = 0
                canvas.font = '13px OpenSans'

                //append it!
                this.$refs.lines.appendChild(canvas)

                var context = canvas.getContext('2d')

                let items = this.item.data.pairs
                for (let i = 0; i < items.length; i++) {
                    let start = { x: 1, y: 1 }
                    let end = { x: 1, y: 1 }
                    let q = this.$refs['question-' + i][0]
                    if (q) {
                        start = {
                            x: q.offsetLeft * 2 + q.offsetWidth * 2,
                            y: Math.max(1, q.offsetTop * 2 + q.offsetHeight),
                        }
                    }

                    let keys = this.getKeyShuffle()
                    let a = this.$refs['answer-' + keys.indexOf(i)]
                    if (a) a = a[0]
                    if (a) {
                        end = {
                            x: a.offsetLeft * 2,
                            y: Math.max(1, a.offsetTop * 2 + a.offsetHeight / 2),
                        }
                    }
                    if (context) {
                        // draw the line
                        context.beginPath()
                        context.lineWidth = this.item.style.font_size / 2
                        context.strokeStyle = '#dc3545'
                        context.moveTo(start.x - context.lineWidth, start.y)
                        context.lineTo(end.x + context.lineWidth, end.y + context.lineWidth)
                        context.stroke()
                    }
                }
            }
        },
        getKeyShuffle() {
            // get our shuffler
            let c = new Chance(this.item.data.shuffle_seed)

            //recreate the keys from our array
            let keys = range(0, this.item.data.pairs.length + this.item.data.fakeAnswers.length)

            //shuffle those keys with the same seed
            return c.shuffle(keys)
        },
        getAnswer(val) {
            let keyShuffle = this.getKeyShuffle()
            //return the answer value
            return this.indexToLetter(keyShuffle.indexOf(val))
        },
        scrambleWord(word, seed) {
            if (!seed) {
                seed = 1
            }

            if (!this.canBeShuffled(word)) {
                // can't scramble a short word!
                return word?.toLowerCase().replace(/ /g, '_')
            }

            var newWord = word
            do {
                let c = new Chance(seed)
                var letters = word.split('')
                newWord = c.shuffle(letters).join('')
                seed++
            } while (newWord == word)

            return newWord.toLowerCase().replace(/ /g, '_')
        },
        getDefinition(item) {
            this.currentWidget.focusedItem = this.item
            this.currentWidget.focusedItem.data.index = item.index
            this.currentWidget.focusedItem.data.fake = item.fake
            this.currentWidget.focusedItem.data.target = 'definition'
            this.$store.dispatch('document/setWidgetStatus', this.currentWidget)
        },
        handleTerm(index) {
            this.currentWidget.focusedItem = this.item
            this.currentWidget.focusedItem.data.index = index
            this.currentWidget.focusedItem.data.target = 'term'
            this.$store.dispatch('document/setWidgetStatus', this.currentWidget)
        },
    },
})
</script>

<style lang="scss" scoped>
[v-cloak] {
    display: none;
}

.matching-preview {
    .matching-container {
        border: 1px solid #212121;
        border-radius: 3px;
    }
}
</style>
