<template>
    <div class="vue-load-image">
        <svg
            class="loading-image"
            width="181"
            height="152"
            viewBox="0 0 181 152"
            xmlns="http://www.w3.org/2000/svg"
            :style="imageStyle"
        >
            <g fill="none" fill-rule="evenodd">
                <path
                    d="M162.498 119.702L78.666 104.92c-.603-.106-.99-.777-.862-1.498L92.51 20.019c.128-.722.72-1.22 1.322-1.113l83.832 14.781c.604.107.988.776.861 1.499l-14.706 83.403c-.127.72-.718 1.22-1.321 1.113z"
                    stroke="#B3B3FC"
                    stroke-width="1.9"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-dasharray="11.38480019569397,11.38480019569397"
                />
                <path
                    d="M143.855 129.22L31.319 149.061c-.808.143-1.604-.526-1.775-1.494L9.803 35.608c-.171-.97.348-1.869 1.156-2.011l112.536-19.843c.81-.143 1.602.525 1.773 1.495l19.742 111.96c.17.967-.345 1.868-1.155 2.01z"
                    stroke="#B3B3FC"
                    stroke-width="1.9"
                    fill="#FFF"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-dasharray="11.38480019569397,11.38480019569397"
                />
                <g fill="#8080F1">
                    <path
                        d="M87.762 67.88c-1.815-.757-4.52-.723-6.521.673-2.356 1.643-3.119 4.88-1.946 8.002 1.26 3.36 4.705 4.918 7.72 3.782 3.14-1.182 4.669-4.777 3.848-8.323-.42-1.813-1.456-3.45-3.1-4.134"
                    />
                    <path
                        d="M106.364 96.93a97.402 97.402 0 00-12.232-12.1c-.67-.555-1.656-.28-2.126.376l-6.29 8.773c-.34.474-2.263 4-2.859 3.987-.682-.014-3.64-3.764-4.12-4.28L64.056 77.88c-.86-.926-2.082-.64-2.598.458-3.941 8.41-5.304 17.898-7.094 27.397l-7.117-40.36s36.27-6.535 52.504-9.45c2.362 13.996 5.173 26.889 6.613 41.004m4.962 4.096c.01-.107-5.14-32.243-7.873-48.21-.136-.788-.671-1.19-1.255-1.27a1.4 1.4 0 00-.964-.15l-54.082 9.71c-.068.011-.126.035-.188.055l-3.06.54c-.952.167-1.3 1.018-1.178 1.798a1.72 1.72 0 00.023.413l8.715 49.426c.119.675 1.26.394 1.917.28 12.362-2.149 37.493-6.197 57.464-10.102.973-.348.484-2.487.481-2.49"
                    />
                </g>
                <g fill="#C7EBFF">
                    <path d="M144.938 13.075c4.368-1.735 4.119-11.97 3.633-13.075-1.773 1.418-3.633 13.075-3.633 13.075" />
                    <path d="M153.102 8.158c-1.254-3.14-11-3.554-11.618-3.124-.617.43 8.857 3.832 11.618 3.124" />
                </g>
                <g fill="#C7EBFF">
                    <path d="M0 121.69c3.573 3.053 12.508-1.946 13.259-2.891-2.082-.905-13.259 2.89-13.259 2.89" />
                    <path d="M8.159 126.614c2.192-2.574-1.992-11.388-2.66-11.732-.668-.345.745 9.621 2.66 11.732" />
                </g>
                <g fill="#C7EBFF">
                    <path d="M158.916 145.503c4.7-.042 8.159-9.68 8.104-10.884-2.164.684-8.104 10.884-8.104 10.884" />
                    <path d="M168.303 143.862c-.036-3.381-8.977-7.283-9.708-7.104-.73.178 6.878 6.769 9.708 7.104" />
                </g>
            </g>
        </svg>
        <b-spinner v-if="showPreLoader" small variant="primary" label="Spinning" class="preloader-spinner" />
    </div>
</template>

<script>
export default {
    props: {
        showPreLoader: {
            type: Boolean,
            default: false,
        },

        width: {
            type: Number,
            default: 120,
        },

        height: {
            type: Number,
            default: 120,
        },
    },

    computed: {
        imageStyle() {
            return {
                width: `${this.width}px`,
                height: `${this.height}px`,
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.vue-load-image {
    img {
        width: 100%;
        height: 100%;

        &.preloader {
            padding: 10%;
            border: 1px solid #8080f1;
            opacity: 0.6;
        }
    }

    .preloader-spinner {
        position: absolute;
        top: 3px;
        right: 3px;
        border-width: 1px;
        border-radius: 50%;
    }
}
</style>
